import { Observable, of } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { StateKey, TransferState, makeStateKey } from '@angular/core';
import { STATE_TRANSLATE, STATE_TRANSLATE_SEO } from '@shared/const/settings';

let readFileSync: (path: string, encoding: string) => string;
let join: (...paths: string[]) => string;

if (typeof window === 'undefined') {
  ({ readFileSync } = require('fs'));
  ({ join } = require('path'));
}

export class TranslateServerLoader implements TranslateLoader {
  constructor(
    private transferState: TransferState,
    private doc: Document,
    private prefix = 'public/i18n/',
    private suffix = '.json'
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const assetsPath = join(this.prefix, lang + this.suffix);
    const data = JSON.parse(readFileSync(assetsPath, 'utf8'));
    const key = makeStateKey<any>(STATE_TRANSLATE + lang);
    this.transferState.set(key, data);

    const html = this.doc.getElementsByTagName('html')[0];
    if (html) {
      html.setAttribute('dir', data['global']?.direction || 'ltr');
      html.setAttribute('lang', lang);
    }

    const jsonDataSeo = JSON.parse(readFileSync(`${this.prefix}seo/${lang}${this.suffix}`, 'utf8'));
    const keySeo: StateKey<number> = makeStateKey<number>(STATE_TRANSLATE_SEO + lang);
    this.transferState.set(keySeo, jsonDataSeo);

    return of(data);
  }
}
